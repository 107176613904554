import React from 'react';
import { useRouter } from 'next/router';

import NewBriefPage from '@/pages/new-brief/[key]';
import BriefPage from '@/pages/brief/[key]';

import DynamicLayout from '@/components/DynamicLayout';

export const IN_PROD = process.env.NEXT_PUBLIC_ENV === 'prod';

function PageNotFound() {
    const router = useRouter();
    const pathElements = router.asPath.split('/');
    const filteredPathElements = pathElements.filter((element) => element && element[0] !== '?');
    const briefKey = (filteredPathElements.length === 1) ? new URL(router.asPath, 'https://wherever').pathname.substring(1).split('/')[0] : null;

    if (!IN_PROD) {
        return <NewBriefPage briefKey={briefKey} />;
    }

    return (
        <BriefPage briefKey={briefKey} />
    );
}

PageNotFound.getLayout = function getLayout(page) {
    return <DynamicLayout>{page}</DynamicLayout>;
};

export default PageNotFound;
