import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { Spinner } from 'flowbite-react';

import { withOptionalAuth } from '@/components/with_auth';
import routes from '@/services/routes';
import { SIGNED_IN } from '@/components/with_auth/Actions';
import InfoCard from '@/components/bio/InfoCard';
import BioBrief from '@/components/bio/BioBrief';
import { FETCH_BRIEF } from '@/components/index/Actions';

function BriefPage({ briefKey = null }) {
    const router = useRouter();
    const url = router ? router.query : {};

    const [loggedIn, setLoggedIn] = useState(null);
    const [userRelationshipInformation/* , setUserRelationshipInformation */] = useState([
        // { title: 'Relationship Status', value: 'Close Friend' },
        // { title: 'First Interaction', value: 'June 4, 2004' },
        // { title: 'Last Interaction', value: '1 Week ago' },
        // { title: 'Last Meeting', value: '1 Month ago' },
    ]);

    const { loading: loadingUser, data: userData } = useQuery(SIGNED_IN);
    const { loading, data } = useQuery(FETCH_BRIEF, {
        variables: {
            key: briefKey || url.key,
            allowRandom: true,
        },
        fetchPolicy: 'no-cache',
    });
    const brief = data?.brief;

    // const getNewValueGivenKey = (key: string) => {
    //     if (key === 'Relationship Status') {
    //         return 'Close Friend'; // return from users relationship
    //     }
    //     if (key === 'First Interaction') {
    //         return 'June 1, 2004'; // return from users relationship
    //     }
    //     if (key === 'Last Interaction') {
    //         return '1 Month Ago'; // return from users relationship
    //     }
    //     if (key === 'Last Meeting') {
    //         return '3 Days Ago'; // return from users relationship
    //     }
    //     return '';
    // };

    // useEffect(() => {
    //     // update userRelationshipInformation state
    //     setUserRelationshipInformation((prevItems) => {
    //         // Use the map function to create a new array with updated values
    //         const updatedItems = prevItems.map((item) => ({
    //             ...item,
    //             value: getNewValueGivenKey(item.title),
    //         }));

    //         // Return the updated array
    //         return updatedItems;
    //     });
    // }, [/* user data */]);

    // check if user is signed in to display info

    useEffect(() => {
        if (loadingUser) {
            return;
        }
        setLoggedIn(!!userData?.signedIn);
    }, [userData, loadingUser]);

    useEffect(() => {
        if (brief) {
            let title = 'Sixty';
            if (brief.fullName) {
                title = `${title} Brief | ${brief.fullName}`;
            }
            document.title = title;
        }
    }, [brief]);

    return (
        <div className="max-w-[1200px] mx-auto">
            {(loading || brief === null)
                ? <Spinner size="xl" />
                : (
                    <div>
                        {(brief.randomlySelected && (
                            <center>
                                <h1 className="text-xl font-bold mb-2">Whoops!  We couldn&apos;t find what you were looking for!</h1>
                                <h2 className="text-base md:text-lg ml-2 mr-2">
                                    Maybe you were looking for
                                    {' '}
                                    {brief.fullName}
                                    ?
                                </h2>
                                <br />
                            </center>
                        ))}

                        <div className="w-full flex flex-col md:flex-row justify-between items-center mb-5">
                            {(!loggedIn && (<img src="/logo/mark.png" alt="graphic" className="h-16 mb-5 md:mb-0" />))}
                            {
                                ((loggedIn && userRelationshipInformation.length > 0) && (
                                    userRelationshipInformation.map((cardInfo) => (
                                        cardInfo.value && (
                                            <InfoCard
                                                key={cardInfo.title}
                                                headerText={cardInfo.value}
                                                subText={cardInfo.title}
                                            />
                                        )
                                    ))
                                )) || (loggedIn && (
                                    <div className="py-2" />
                                )) || (
                                    <div className="w-full max-w-[478px] bg-progressBarBlue flex flex-col md:flex-row justify-between items-center rounded-lg p-4 mt-16 md:m-0">
                                        <div className="flex flex-col ">
                                            <p className="text-cirrusWhite font-bold text-base text-center md:text-left mb-2 md:mb-0">A Sixty brief makes meetings 2x better</p>
                                            <span className="text-cirrusWhite font-normal text-sm text-center md:text-left mb-2 md:mb-0">Join Sixty to get your free brief today.</span>
                                        </div>

                                        <button type="button" onClick={() => router.push(routes.SIGN_UP)} className="h-[34px] w-[108px] flex shrink-0 gap-2 justify-center items-center text-gray-900 bg-cirrusWhite border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-2 dark:bg-gray-800 dark:text-cirrusWhite dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                            <img src="/images/bio/file-outline.png" alt="file" className="h-3 w-3" />
                                            <span className="text-xs min-w-[70px]">Get started</span>
                                        </button>
                                    </div>
                                )
                            }

                        </div>
                        <BioBrief brief={brief} user={userData?.user} />
                    </div>
                )}

        </div>

    );
}

export default withOptionalAuth(BriefPage);
